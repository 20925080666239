import { Component } from '@angular/core';
import { AgefiphService } from '../services/agefiph.service';

@Component({
    selector: 'app-credits',
    templateUrl: './credits.component.html',
    styleUrls: ['./credits.component.scss']
})
export class CreditsComponent {

    constructor(public agefiphService: AgefiphService) {
        this.agefiphService.setTitle(agefiphService.datas.data.wordings['ui.page.credits'] + ' | ' + agefiphService.datas.data.wordings['ui.page.agefiph'])
    }
}

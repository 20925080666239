import { Component } from '@angular/core';
import { AgefiphService } from '../services/agefiph.service';

@Component({
    selector: 'app-btn-menu',
    templateUrl: './btn-menu.component.html',
    styleUrls: ['./btn-menu.component.scss']
})
export class BtnMenuComponent {

    tabIndex = '0';

    constructor(public agefiphService: AgefiphService) {
        this.agefiphService.showPopupReponse$.subscribe(b => {
            this.tabIndex = b ? "-1" : "0";
        });
    }

}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Player } from '../entites/player';
import { AgefiphService } from '../services/agefiph.service';

@Component({
    selector: 'app-remerciement',
    templateUrl: './remerciement.component.html',
    styleUrls: ['./remerciement.component.scss']
})
export class RemerciementComponent {

    showPopupCompteSubject = false;
    email = '';
    ok = false;
    error = '';
    emailInvalid = false;

    constructor(public agefiphService: AgefiphService,
        private router: Router) {
        this.agefiphService.showPopupCompteSubject$.subscribe(b => {
            this.showPopupCompteSubject = b;
        });
        this.agefiphService.setTitle(agefiphService.datas.data.wordings['ui.page.remerciements'] + ' | ' + agefiphService.datas.data.wordings['ui.page.agefiph'])
    }

    checkEmail(){
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.emailInvalid = !re.test(this.email);
    }

    createCompte() {
        let player = new Player();
        player.companyUid = this.agefiphService.slug;
        player.playerUuid = this.agefiphService.playerUuid;
        player.email = this.email;
        this.agefiphService.setPlayer(player).subscribe(player => {
            this.ok = true;
            if (player.success) {
                this.agefiphService.setCompte(player);
            } else {
                this.error = player.data.errorMessage;
            }
        });
    }

}

import { Component } from '@angular/core';
import { AgefiphService } from '../services/agefiph.service';

@Component({
    selector: 'app-bilan',
    templateUrl: './bilan.component.html',
    styleUrls: ['./bilan.component.scss']
})
export class BilanComponent {

    i = 3;

    constructor(public agefiphService: AgefiphService) {
        this.agefiphService.setTitle(agefiphService.datas.data.wordings['ui.page.bilan'] + ' | ' + agefiphService.datas.data.wordings['ui.page.agefiph'])
    }

    previous() {
        if (this.agefiphService.orientation === 'portrait') {
            if (this.i === 3) {
                this.i = 1;
            } else {
                this.i++;
            }
        }
    }

    next() {
        if (this.agefiphService.orientation === 'portrait') {
            if (this.i === 1) {
                this.i = 3
            } else {
                this.i--;
            }
        }
    }
}

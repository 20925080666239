import { Component, Input } from '@angular/core';
import { AgefiphService } from 'src/app/services/agefiph.service';

@Component({
    selector: 'app-reponse-text-qcu',
    templateUrl: './reponse-text-qcu.component.html',
    styleUrls: ['./reponse.component.scss']
})
export class ReponseTextQcuComponent {

    @Input() rep: string;

    @Input() i: number;

    @Input() textSize: string;
    @Input() lineSize: string;

    tabIndex = '0';

    constructor(public agefiphService: AgefiphService) {
        if (!this.agefiphService.revoir) {
            this.agefiphService.activeValidation = false;
        }
        this.agefiphService.showPopupReponse$.subscribe(b => {
            this.tabIndex = b ? "-1" : "0";
        });
    }

    select() {
        this.agefiphService.activeValidation = false;
        this.agefiphService.repQCUMSelected[this.i - 1] = this.agefiphService.repQCUMSelected[this.i - 1] === '1' ? '0' : '1';
        for (var i = 0; i < this.agefiphService.repQCUMSelected.length; ++i) {
            if (i === (this.i - 1) && this.agefiphService.repQCUMSelected[i] === '1') {
                this.agefiphService.activeValidation = true;
            } else {
                this.agefiphService.repQCUMSelected[i] = '0';
            }
        }
    }

}

import { Component, ElementRef, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Progression } from '../entites/progression';
import { AgefiphService } from '../services/agefiph.service';

@Component({
    selector: 'app-compte',
    templateUrl: './compte.component.html',
    styleUrls: ['./compte.component.scss']
})
export class CompteComponent implements OnInit, OnDestroy {

    progression = false;

    compte = '';

    bg2 = true;
    bg3 = false;
    bg4 = false;
    bg5 = false;

    i = 0;

    top = '0';
    pointstop = '-500vh';

    @ViewChild('badge') badge: ElementRef;
    @ViewChild('logo') logo: ElementRef;


    constructor(public agefiphService: AgefiphService,
        private router: Router) {
        this.agefiphService.setTitle(agefiphService.datas.data.wordings['ui.page.compte'] + ' | ' + agefiphService.datas.data.wordings['ui.page.agefiph'])
    }


    ngOnInit(): void {
        this.i = this.agefiphService.firstLoad ? 0 : 5;
        if (this.i === 0) {
            this.animLogo();
        }
    }

    ngOnDestroy() {
        clearInterval(this.agefiphService.animationLogo);
        clearInterval(this.agefiphService.animationBadgeAccueil);
    }

    animBadge() {
        let step = 0;
        this.agefiphService.animationBadgeAccueil = setInterval(() => {
            this.badge.nativeElement.src = './assets/animations/badge_1/ADP_gold_000' + step + '.png';
            if (step === 23) {
                clearInterval(this.agefiphService.animationBadgeAccueil)
            } else {
                step++;
            }
        }, 80);
    }

    animLogo() {
        this.bg2 = true;
        let step = 1;
        this.agefiphService.animationLogo = setInterval(() => {
            this.logo.nativeElement.src = './assets/animations/logo/Animation_Logo_000' + step + '.png';
            if (step === 18) {
                clearInterval(this.agefiphService.animationLogo)
            } else {
                step++;
            }
        }, 80);
    }

    setBg() {
        if (this.i === 0) {
            this.scroll(2);
            clearInterval(this.agefiphService.animationLogo);
        } else if (this.i === 1) {
            this.scroll(3);
            this.animBadge();
        } else if (this.i === 2 && this.agefiphService.datas.data.company.id !== 0) {
            this.scroll(4);
            this.agefiphService.firstLoad = false;
            clearInterval(this.agefiphService.animationBadgeAccueil);
            setTimeout(() => {
                window.document.getElementById("main-container").scrollTo(0, 0);
                this.i = 4;
            }, 3000)
        }
        this.i++;
    }

    jouer() {
        this.agefiphService.succes = false;
        this.agefiphService.tableauBilan = new Array<any>();
        this.agefiphService.evaluationData = new Progression();
        this.router.navigate(['/' + this.agefiphService.slug + '/questions']);
    }

    private scroll(bgIndex) {
        let elem = window.document.getElementById("bg-" + bgIndex);
        elem.scrollIntoView({ behavior: "smooth", block: "center" });
    }
}

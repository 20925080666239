import { Component, Input, OnChanges } from '@angular/core';
import { AgefiphService } from 'src/app/services/agefiph.service';

@Component({
    selector: 'app-reponse-image-qcm',
    templateUrl: './reponse-image-qcm.component.html',
    styleUrls: ['./reponse.component.scss']
})
export class ReponseImageQcmComponent {

    @Input() rep: string;

    @Input() alternative: string;

    @Input() i: number;

    constructor(public agefiphService: AgefiphService) {
        if (!this.agefiphService.revoir) {
            this.agefiphService.activeValidation = false;
        }
    }

    select() {
        this.agefiphService.repQCUMSelected[this.i - 1] = this.agefiphService.repQCUMSelected[this.i - 1] === '1' ? '0' : '1';
        var count = 0;
        for (var i = 0; i < this.agefiphService.repQCUMSelected.length; ++i) {
            if (this.agefiphService.repQCUMSelected[i] === '1')
                count++;
        }
        if (count > 0) {
            this.agefiphService.activeValidation = true;
        } else {
            this.agefiphService.activeValidation = false;
        }
    }

}

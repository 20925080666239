import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LeaderBoardResult } from '../entites/leaderBoardResult';
import { AgefiphService } from '../services/agefiph.service';

@Component({
    selector: 'app-leader-board',
    templateUrl: './leader-board.component.html',
    styleUrls: ['./leader-board.component.scss']
})
export class LeaderBoardComponent implements OnDestroy {

    showPopupCompteSubject = false;

    lbGeneral = new LeaderBoardResult();

    KO = false;
    lbInterval: any;

    constructor(public agefiphService: AgefiphService,
        private router: Router) {
        this.agefiphService.showPopupCompteSubject$.subscribe(b => {
            this.showPopupCompteSubject = b;
        });
        this.getLeaderBoard();
        this.agefiphService.setTitle(agefiphService.datas.data.wordings['ui.page.leaderboard'] + ' | ' + agefiphService.datas.data.wordings['ui.page.agefiph'])
    }

    ngOnDestroy() {
        clearInterval(this.lbInterval);
    }

    getLeaderBoard() {
        this.agefiphService.getLeaderBoard().subscribe(lb => {
            this.lbGeneral = lb;
            this.pageScroll()
        });
    }

    pageScroll() {
        if (document.getElementsByClassName("lb")[0]) {
            this.lbInterval = setInterval(() => {
                document.getElementsByClassName("lb")[0].scrollBy(0, 1)
            }, 100);
        }
    }
}

import { Component } from '@angular/core';
import { AgefiphService } from '../services/agefiph.service';

@Component({
    selector: 'app-btn-retour',
    templateUrl: './btn-retour.component.html',
    styleUrls: ['./btn-retour.component.scss']
})
export class BtnRetourComponent {
    
    constructor(public agefiphService: AgefiphService) {

    }

}

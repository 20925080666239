import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AgefiphService } from 'src/app/services/agefiph.service';

@Component({
    selector: 'app-question',
    templateUrl: './question.component.html',
    styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnDestroy {

    showReponse = false;
    isGood = false;
    showPopupReponse = false;
    repIci = false;

    classToApply = '';

    subscriptions: Subscription[] = [];

    i = 1;

    constructor(public agefiphService: AgefiphService,
        public router: Router) {
        this.agefiphService.setTitle(agefiphService.datas.data.wordings['ui.page.quiz'] + ' | ' + agefiphService.datas.data.wordings['ui.page.agefiph'])
        this.init();
        this.agefiphService.showPopupReponse$.subscribe(b => {
            this.showPopupReponse = b;
            if (this.showPopupReponse) {
                this.classToApply += ' bright';
            }
        });
        this.subscriptions.push(this.agefiphService.repSelected$.subscribe(value => {
            this.checkAnswer();
        }));

        this.subscriptions.push(this.agefiphService.repQCUMSelected$.subscribe(value => {
            this.checkReponse();
        }));

        this.subscriptions.push(this.agefiphService.resizeSubject$.subscribe(value => {
            this.init();
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    init() {
        if (!this.agefiphService.revoir) {
            this.agefiphService.activeValidation = false;
            this.agefiphService.repSelected = null;
        }
        if (this.agefiphService.index > 21) {
            this.agefiphService.q = this.agefiphService.datas.data.questions[this.agefiphService.index - 1];
        } else {
            this.agefiphService.q = this.agefiphService.datas.data.questions[this.agefiphService.index];
        }
        if (this.agefiphService.q) {
            if (this.agefiphService.index === 21) {
                this.setClassCss();
                this.agefiphService.showPopupReponse.next(true);
            } else if (!this.agefiphService.q.display_conditions || this.checkCondition()) {
                this.agefiphService.typeRep = this.agefiphService.q.type.includes('image') ? '_image' : '_text';
                this.agefiphService.nbRep = this.checkNbRep();
                this.repIci = this.agefiphService.q.type === 'bool' ||
                    this.agefiphService.q.type === 'open_text' ||
                    this.agefiphService.q.type === 'open_number';
                this.setClassCss();
            } else {
                this.agefiphService.index++;
                this.init();
            }
        } else {
            this.router.navigate(['/' + this.agefiphService.slug + '/resultat']);
        }
    }

    checkCondition() {
        let qConcernee = this.agefiphService.tableauBilan.find(t => t[2] === this.agefiphService.q.display_conditions.substring(0, 3));
        let rep = qConcernee[3];
        let conditionLettre = this.agefiphService.q.display_conditions.substring(4, 5);
        if (rep.includes(conditionLettre)) {
            return true;
        } else {
            return false;
        }
    }

    //QCU + boolean
    checkAnswer() {
        let lettres = new Array();
        if (this.agefiphService.q.type === 'bool') {
            if (this.agefiphService.q.answer1_is_right === this.agefiphService.repSelected) {
                this.isGood = true;
                this.calculScoreRight(this.agefiphService.q.theme_FK);
            } else {
                this.isGood = false;
            }
            lettres.push(this.agefiphService.repSelected === '1' ? 'A' : 'B');
            this.prepareBilan([this.agefiphService.q.answer1_text], lettres);
        } else {
            if (this.agefiphService.q['answer' + this.agefiphService.repSelected + '_is_right'] === "1") {
                this.isGood = true;
                this.calculScoreRight(this.agefiphService.q.theme_FK);
            } else {
                this.isGood = false;
            }
            this.checkLettre(this.agefiphService.repSelected, lettres);
            this.prepareBilan([this.agefiphService.q['answer' + this.agefiphService.repSelected + '_text']], lettres);
        }
        this.showPopup();
    }

    //text et number
    checkTextRep() {
        if (this.agefiphService.q.answer1_text.toString() === this.agefiphService.repSelected.toString()) {
            this.isGood = true;
            this.calculScoreRight(this.agefiphService.q.theme_FK);
        } else {
            this.isGood = false;
        }
        this.prepareBilan([this.agefiphService.repSelected], []);
        this.showPopup();
    }

    //QCM 
    checkReponse() {
        let n = 0;
        let nbFalse = 0;
        let nbRight = 0;
        this.isGood = true;
        let answers = new Array();
        let lettres = new Array();
        // Cas où on des points pour chaque bonne réponse
        if (this.agefiphService.q.theme_FK === 3) {
            let nbRep = 0;
            while (n < this.agefiphService.nbRep) {
                if (this.agefiphService.q['answer' + (n + 1) + '_is_right'] === '1') {
                    if (this.agefiphService.index !== 0) {
                        this.agefiphService.nbBonneRep3++;
                    }
                    nbRep++;
                }
                if (this.agefiphService.repQCUMSelected[n] === this.agefiphService.q['answer' + (n + 1) + '_is_right'] && this.agefiphService.q['answer' + (n + 1) + '_is_right'] === '1') {
                    nbRight++;
                }
                if (this.agefiphService.repQCUMSelected[n] === '1') {
                    answers.push(this.agefiphService.q['answer' + (n + 1) + '_text']);
                    this.checkLettre(n + 1, lettres);
                }
                n++;
            }
            if (nbRight * 100 / nbRep >= this.agefiphService.datas.data.params.minPercentToWinTheme3) {
                this.isGood = true;
            } else {
                this.isGood = false;
            }
            if (this.agefiphService.index !== 0) {
                this.calculScore(nbRight);
            }

        } else {
            while (n < this.agefiphService.nbRep) {
                if (this.agefiphService.repQCUMSelected[n] !== this.agefiphService.q['answer' + (n + 1) + '_is_right']) {
                    this.isGood = false;
                    nbFalse++;
                } else {
                    nbRight++;
                }
                if (this.agefiphService.repQCUMSelected[n] === '1') {
                    answers.push(this.agefiphService.q['answer' + (n + 1) + '_text']);
                    this.checkLettre(n + 1, lettres);
                }
                n++;
            }
            if (this.isGood) {
                this.calculScoreRight(this.agefiphService.q.theme_FK);
            }
        }
        this.prepareBilan(answers, lettres);
        this.showPopup();
    }

    showPopup() {
        this.showPopupReponse = true;
        this.agefiphService.showPopupReponse.next(true);
    }

    showRep() {
        this.agefiphService.showPopupReponse.next(false);
        if (!this.agefiphService.revoir) {
            this.agefiphService.repQCUMSelected = ['0', '0', '0', '0', '0', '0'];
        }
        this.showReponse = true;
    }

    prepareBilan(r: string[], rLettre: string[]) {
        this.agefiphService.tableauBilan.push([r, this.isGood, this.agefiphService.q.id_gsheet, rLettre, this.agefiphService.q.theme_FK, this.agefiphService.q.question])
    }

    checkLettre(n, lettres) {
        if (n.toString() === '1') {
            lettres.push('A')
        } else if (n.toString() === '2') {
            lettres.push('B')
        } else if (n.toString() === '3') {
            lettres.push('C')
        } else if (n.toString() === '4') {
            lettres.push('D')
        } else if (n.toString() === '5') {
            lettres.push('E')
        } else if (n.toString() === '6') {
            lettres.push('F')
        }
    }


    checkNbRep() {
        if (this.agefiphService.q['answer6' + this.agefiphService.typeRep]) {
            this.agefiphService.arrayNbRep = [1, 2, 3, 4, 5, 6];
            return 6;
        } else if (this.agefiphService.q['answer5' + this.agefiphService.typeRep]) {
            this.agefiphService.arrayNbRep = [1, 2, 3, 4, 5];
            return 5;
        } else if (this.agefiphService.q['answer4' + this.agefiphService.typeRep]) {
            this.agefiphService.arrayNbRep = [1, 2, 3, 4];
            return 4;
        } else if (this.agefiphService.q['answer3' + this.agefiphService.typeRep]) {
            this.agefiphService.arrayNbRep = [1, 2, 3];
            return 3;
        } else {
            this.agefiphService.arrayNbRep = [1, 2];
            return 2;
        }
    }

    setClassCss() {
        if (this.agefiphService.q.theme_FK === 1) {
            this.classToApply = this.agefiphService.orientation === 'portrait' ? 'bg-conn bg-img bg-mains' : 'bg-conn bg-img bg-mains-web';
        } else if (this.agefiphService.q.theme_FK === 2) {
            this.classToApply = this.agefiphService.orientation === 'portrait' ? 'bg-prat bg-img bg-mains' : 'bg-prat bg-img bg-mains-web';
        } else if (this.agefiphService.q.theme_FK === 3) {
            this.classToApply = this.agefiphService.orientation === 'portrait' ? 'bg-eng bg-img bg-mains' : 'bg-eng bg-img bg-mains-web';
        } else {
            this.classToApply = this.agefiphService.orientation === 'portrait' ? 'bg-ent bg-img bg-mains' : 'bg-ent bg-img bg-mains-web';
        }

        if (this.agefiphService.q.type === 'bool' || this.agefiphService.q.type.startsWith('qc')) {
            this.classToApply += ' page-question'
        } else {
            this.classToApply += ' page-question-input';
        }
    }

    calculScore(nbRight: number) {
        if (this.agefiphService.q.theme_FK !== 0) {
            this.agefiphService.evaluationData.scores['3'] += nbRight;
        }
    }

    calculScoreRight(theme: number) {
        if (this.agefiphService.q.theme_FK !== 0) {
            this.agefiphService.evaluationData.scores[theme.toString()] += 1;
        }
    }

    calculScoreFalse(theme: number) {
        if (this.agefiphService.q.theme_FK !== 0) {
            this.agefiphService.evaluationData.scores[theme.toString()] += this.agefiphService.q.scores['theme_' + theme.toString()].wrongAnswer;
        }
    }

}

import { Component, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { SharerMethod } from 'ngx-sharebuttons';
import { AgefiphService } from '../services/agefiph.service';

@Component({
    selector: 'app-popup-badge',
    templateUrl: './popup-badge.component.html',
    styleUrls: ['./popup-badge.component.scss']
})
export class PopupBadgeComponent implements AfterViewInit{
    @Output() showPopup: EventEmitter<boolean> = new EventEmitter();

    sharerMethod = SharerMethod.Window;

    constructor(public agefiphService: AgefiphService) {
    }


    ngAfterViewInit(): void {
      document.getElementById('ShareFB').removeAttribute("aria-label");
      document.getElementById('ShareTwitter').removeAttribute("aria-label");
      document.getElementById('ShareLinkedin').removeAttribute("aria-label");
    }

    closePopup() {
        this.showPopup.emit(false);
    }

    logShare(plateform: string) {
        this.agefiphService.logShare(plateform).subscribe(() => {
            //Rien
        })
    }
}

import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Player } from '../entites/player';
import { AgefiphService } from '../services/agefiph.service';

@Component({
    selector: 'app-resultat',
    templateUrl: './resultat.component.html',
    styleUrls: ['./resultat.component.scss']
})
export class ResultatComponent implements OnDestroy {

    good = 0;
    showPopupCompteSubject = false;
    player = new Player();
    badge = false;
    showPopCompte = false;
    pseudo = '';

    @ViewChild('badge1') badge1: ElementRef;

    constructor(public agefiphService: AgefiphService,
        private router: Router) {
        this.agefiphService.setTitle(agefiphService.datas.data.wordings['ui.page.resultats'] + ' | ' + agefiphService.datas.data.wordings['ui.page.agefiph'])
        this.agefiphService.index = 0;
        this.saveBadge();
        this.agefiphService.showPopupCompteSubject$.subscribe(b => {
            this.showPopupCompteSubject = b;
        });
    }

    ngOnDestroy() {
        clearInterval(this.agefiphService.animationBadge1);
    }

    saveBadge() {
        let l1 = this.agefiphService.datas.data.questions.filter(q => q.theme_FK === 1).length;
        let l2 = this.agefiphService.datas.data.questions.filter(q => q.theme_FK === 2).length;

        if (this.agefiphService.evaluationData.scores['1'] * 100 / l1 >= this.agefiphService.datas.data.params.minPercentToWinTheme1) {
            this.agefiphService.eval3 = true;
            this.good++;
        }
        if (this.agefiphService.evaluationData.scores['2'] * 100 / l2 >= this.agefiphService.datas.data.params.minPercentToWinTheme2) {
            this.agefiphService.eval2 = true;
            this.good++;
        }
        if (this.agefiphService.evaluationData.scores['3'] * 100 / this.agefiphService.nbBonneRep3 >= this.agefiphService.datas.data.params.minPercentToWinTheme3) {
            this.agefiphService.eval1 = true;
            this.good++;
        }
        let total = l1 + l2 + this.agefiphService.nbBonneRep3;
        let score = this.agefiphService.evaluationData.scores['1'] + this.agefiphService.evaluationData.scores['2'] + this.agefiphService.evaluationData.scores['3'];

        if (score * 100 / total >= this.agefiphService.datas.data.params.minPercentToWinGame) {
            this.agefiphService.evaluationData.badge = true;
            this.agefiphService.succes = true;
            this.animBadge1();
        }

        this.agefiphService.saveEvaluation().subscribe(() => {
            this.player.playerUuid = this.agefiphService.playerUuid;
            this.player.companyUid = this.agefiphService.slug;
        })
    }


    animBadge1() {
        let step = 0;
        this.agefiphService.animationBadge1 = setInterval(() => {
            this.badge1.nativeElement.src = './assets/animations/badge_1/ADP_gold_000' + step + '.png';
            if (step === 23) {
                clearInterval(this.agefiphService.animationBadge1)
            } else {
                step++;
            }
        }, 50);
    }

    setPseudo() {
        let player = new Player();
        player.companyUid = this.agefiphService.slug;
        player.playerUuid = this.agefiphService.playerUuid;
        player.pseudo = this.pseudo;
        this.agefiphService.setPlayer(player).subscribe(player => {
            if (player.success) {
                this.agefiphService.setCompte(player);
                this.showPopCompte = false;
                this.router.navigate(['/' + this.agefiphService.slug + '/leaderboard']);
            }
        });
    }

    showPopCompteOrNot() {
        this.agefiphService.whereToGo = 'resultat';
        if (this.agefiphService.succes) {
            this.pseudo = this.agefiphService.playerData.data.pseudo;
            this.showPopCompte = true;
        } else {
            this.router.navigate(['/', this.agefiphService.slug, 'leaderboard']);
        }
    }

}

import { LeaderBoard } from './leaderBoard';

export class LeaderBoardResult {
    data: LeaderBoard;
    success: boolean;

    constructor() {
        this.data = new LeaderBoard();
        this.success = true;
    }
}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LeaderBoardResult } from '../entites/leaderBoardResult';
import { AgefiphService } from '../services/agefiph.service';

@Component({
    selector: 'app-leader-board-entreprise',
    templateUrl: './leader-board-entreprise.component.html',
    styleUrls: ['./leader-board-entreprise.component.scss']
})
export class LeaderBoardEntrepriseComponent {

    showPopupCompteSubject = false;

    lbEnt = new LeaderBoardResult();

    constructor(public agefiphService: AgefiphService,
        private router: Router) {
        this.agefiphService.showPopupCompteSubject$.subscribe(b => {
            this.showPopupCompteSubject = b;
        });
        this.getLeaderBoard();
        this.agefiphService.setTitle(agefiphService.datas.data.wordings['ui.page.leaderboard.entreprise'] + ' | ' + agefiphService.datas.data.wordings['ui.page.agefiph'])
    }

    getLeaderBoard() {
        this.agefiphService.getLeaderBoard().subscribe(lb => {
            this.lbEnt = lb;
        });
    }


}

import { Component } from '@angular/core';
import { AgefiphService } from '../services/agefiph.service';

@Component({
    selector: 'app-mentions',
    templateUrl: './mentions.component.html',
    styleUrls: ['./mentions.component.scss']
})
export class MentionsComponent {

    constructor(public agefiphService: AgefiphService) {
        this.agefiphService.setTitle(agefiphService.datas.data.wordings['ui.page.mentions'] + ' | ' + agefiphService.datas.data.wordings['ui.page.agefiph'])
    }
}

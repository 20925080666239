export class BadgeData {
    name: string;
    companyLogo: boolean;
    background: number;

    constructor(){
        this.name = "";
        this.companyLogo = true;
        this.background = 1;
    }
}

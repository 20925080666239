import { Component } from '@angular/core';
import { AgefiphService } from 'src/app/services/agefiph.service';

@Component({
    selector: 'app-question-text',
    templateUrl: './question-text.component.html',
    styleUrls: ['./question.component.scss']
})
export class QuestionTextComponent {

    constructor(public agefiphService: AgefiphService) {

    }
}

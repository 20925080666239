import { Component } from '@angular/core';
import { AgefiphService } from 'src/app/services/agefiph.service';

@Component({
    selector: 'app-reponse-text',
    templateUrl: './reponse-text.component.html',
    styleUrls: ['./reponse.component.scss']
})
export class ReponseTextComponent {

    constructor(public agefiphService: AgefiphService) {

    }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccueilComponent } from './accueil/accueil.component';
import { PartageBadgeComponent } from './badge/partage-badge.component';
import { CompteComponent } from './compte/compte.component';
import { LeaderBoardEntrepriseComponent } from './leader-board/leader-board-entreprise.component';
import { LeaderBoardComponent } from './leader-board/leader-board.component';
import { MenuComponent } from './menu/menu.component';
import { RetourAccueilComponent } from './menu/retour-accueil.component';
import { BilanComponent } from './questionnaire/bilan.component';
import { QuestionComponent } from './questionnaire/questions/question.component';
import { RemerciementComponent } from './questionnaire/remerciement.component';
import { ResultatComponent } from './questionnaire/resultat.component';
import { AgefiphService } from './services/agefiph.service';
import { CreditsComponent } from './statique/credits.component';
import { MentionsComponent } from './statique/mentions.component';


const routes: Routes = [
    { path: '', redirectTo: 'version-grand-public/accueil', pathMatch: 'full' },
    { path: ':slug', redirectTo: ':slug/accueil', pathMatch: 'full' },
    { path: ':slug/accueil', component: AccueilComponent, canActivate: [AgefiphService] },
    { path: ':slug/menu', component: MenuComponent, canActivate: [AgefiphService] },
    { path: ':slug/retour', component: RetourAccueilComponent, canActivate: [AgefiphService] },
    { path: ':slug/mon-compte', component: CompteComponent, canActivate: [AgefiphService] },
    { path: ':slug/mentions-legales', component: MentionsComponent, canActivate: [AgefiphService] },
    { path: ':slug/credits', component: CreditsComponent, canActivate: [AgefiphService] },
    { path: ':slug/badge-partage', component: PartageBadgeComponent, canActivate: [AgefiphService] },
    { path: ':slug/questions', component: QuestionComponent, canActivate: [AgefiphService] },
    { path: ':slug/bilan', component: BilanComponent, canActivate: [AgefiphService] },
    { path: ':slug/resultat', component: ResultatComponent, canActivate: [AgefiphService] },
    { path: ':slug/leaderboard', component: LeaderBoardComponent, canActivate: [AgefiphService] },
    { path: ':slug/leaderboard-entreprise', component: LeaderBoardEntrepriseComponent, canActivate: [AgefiphService] },
    { path: ':slug/remerciements', component: RemerciementComponent, canActivate: [AgefiphService] },

]
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {


}

export class Player {
    playerUuid: string;
    companyUid: string;
    pseudo: string;
    email: string;

    constructor() {
        this.playerUuid = '';
        this.companyUid = '';
        this.email = '';
        this.pseudo = '';
    }
}



import { NgModule }     from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';

@NgModule({
  imports: [CommonModule]
})
export class Constants {
    public static InterceptorSkipHeader = 'X-Skip-Interceptor';

    public static HttpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }),
        withCredentials: true
    };

    public static FormHttpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest'
        }),
        withCredentials: true
    };

    public static BlobHttpOptions = {
        headers: new HttpHeaders({
            'X-Requested-With': 'XMLHttpRequest',
        }),
        responseType: 'blob' as 'json',
        withCredentials: true,
    };
}

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ShareButtonsConfig, ShareModule, SharerMethod } from 'ngx-sharebuttons';
import { AccueilComponent } from './accueil/accueil.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PartageBadgeComponent } from './badge/partage-badge.component';
import { PopupBadgeComponent } from './badge/popup-badge.component';
import { BtnMenuComponent } from './boutons/btn-menu.component';
import { BtnRetourComponent } from './boutons/btn-retour.component';
import { CompteComponent } from './compte/compte.component';
import { LeaderBoardEntrepriseComponent } from './leader-board/leader-board-entreprise.component';
import { LeaderBoardComponent } from './leader-board/leader-board.component';
import { MenuComponent } from './menu/menu.component';
import { RetourAccueilComponent } from './menu/retour-accueil.component';
import { BilanComponent } from './questionnaire/bilan.component';
import { QuestionImageComponent } from './questionnaire/questions/question-image.component';
import { QuestionTextComponent } from './questionnaire/questions/question-text.component';
import { QuestionComponent } from './questionnaire/questions/question.component';
import { RemerciementComponent } from './questionnaire/remerciement.component';
import { PopReponseComponent } from './questionnaire/reponses/pop-reponse.component';
import { ReponseBoolComponent } from './questionnaire/reponses/reponse-bool.component';
import { ReponseImageQcmComponent } from './questionnaire/reponses/reponse-image-qcm.component';
import { ReponseImageQcuComponent } from './questionnaire/reponses/reponse-image-qcu.component';
import { ReponseNumberComponent } from './questionnaire/reponses/reponse-number.component';
import { ReponseTextQcmComponent } from './questionnaire/reponses/reponse-text-qcm.component';
import { ReponseTextQcuComponent } from './questionnaire/reponses/reponse-text-qcu.component';
import { ReponseTextComponent } from './questionnaire/reponses/reponse-text.component';
import { ReponseComponent } from './questionnaire/reponses/reponse.component';
import { ResultatComponent } from './questionnaire/resultat.component';
import { AgefiphService } from './services/agefiph.service';
import { CreditsComponent } from './statique/credits.component';
import { MentionsComponent } from './statique/mentions.component';
import { FindPipe } from './utils/findPipe';
import { SafePipe } from './utils/safePipe';

const customConfig: ShareButtonsConfig = {
  sharerMethod: SharerMethod.Window,
  windowHeight: 320,
  windowWidth: 480
}

@NgModule({
  declarations: [
    AppComponent,
    AccueilComponent,
    BtnMenuComponent,
    BtnRetourComponent,
    MenuComponent,
    RetourAccueilComponent,
    PopupBadgeComponent,
    CompteComponent,
    MentionsComponent,
    CreditsComponent,
    PartageBadgeComponent,
    QuestionComponent,
    QuestionImageComponent,
    QuestionTextComponent,
    BilanComponent,
    ResultatComponent,
    LeaderBoardComponent,
    LeaderBoardEntrepriseComponent,
    RemerciementComponent,
    ReponseComponent,
    ReponseTextComponent,
    ReponseNumberComponent,
    ReponseImageQcmComponent,
    ReponseImageQcuComponent,
    ReponseTextQcmComponent,
    ReponseTextQcuComponent,
    ReponseBoolComponent,
    PopReponseComponent,
    SafePipe,
    FindPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ShareModule.withConfig(customConfig)
  ],
  providers: [AgefiphService, HttpClient],
  bootstrap: [AppComponent]
})
export class AppModule { }

export class PlayerData {
    success: boolean;
    data: {
        uuid: string;
        pseudo: string;
        progression: {
            badge: boolean
        }
        errorMessage: string;
    };

    constructor() {
        this.success = false;
        this.data = {
            uuid: '',
            pseudo: '',
            progression: {
                badge: false
            },
            errorMessage: ''
        };
    }
}

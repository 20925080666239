import { Component } from '@angular/core';
import { Question } from '../entites/question';
import { AgefiphService } from '../services/agefiph.service';

@Component({
    selector: 'app-retour-accueil',
    templateUrl: './retour-accueil.component.html',
    styleUrls: ['./retour-accueil.component.scss']
})


export class RetourAccueilComponent {


    constructor(public agefiphService: AgefiphService) {
        this.agefiphService.setTitle(agefiphService.datas.data.wordings['ui.page.retour'] + ' | ' + agefiphService.datas.data.wordings['ui.page.agefiph'])
    }

    resetQuestionnaire(){
        this.agefiphService.index = 0;
        this.agefiphService.q = new Question();
        this.agefiphService.repQCUMSelected = ['0', '0', '0', '0', '0', '0'];
        this.agefiphService.repSelected = null;
    }

}

import { Indice } from "./indice";

export class Question {
    id: number;
    id_gsheet: string;
    theme_FK: number;
    type: string;
    display_conditions: string;
    question: string;
    question_image: string;
    external_resource_url: Indice;
    is_editable: number;
    order: number;
    answer1_text: string;
    answer1_image: string;
    answer1_is_right: string;
    answer2_text: string;
    answer2_image: string;
    answer2_is_right: string;
    answer3_text: string;
    answer3_image: string;
    answer3_is_right: string;
    answer4_text: string;
    answer4_image: string;
    answer4_is_right: string;
    answer5_text: string;
    answer5_image: string;
    answer5_is_right: string;
    answer6_text: string;
    answer6_image: string;
    answer6_is_right: string;
    question_image_url: string;
    answer1_image_url: string;
    answer2_image_url: string;
    answer3_image_url: string;
    answer4_image_url: string;
    answer5_image_url: string;
    answer6_image_url: string;
    scores : {};
}

import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, ElementRef, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Player } from '../entites/player';
import { Progression } from '../entites/progression';
import { AgefiphService } from '../services/agefiph.service';

@Component({
    selector: 'app-accueil',
    templateUrl: './accueil.component.html',
    styleUrls: ['./accueil.component.scss']
})
export class AccueilComponent implements OnInit, OnDestroy {

    player = new Player();
    KO = false;

    bg1 = false;
    bg2 = false;
    bg3 = false;
    bg4 = false;
    bg5 = false;

    i = 0;

    @ViewChild('badge') badge: ElementRef;
    @ViewChild('logo') logo: ElementRef;

    constructor(public agefiphService: AgefiphService,
        private router: Router) {
        this.agefiphService.setTitle(agefiphService.datas.data.wordings['ui.page.accueil'] + ' | ' + agefiphService.datas.data.wordings['ui.page.agefiph'])
    }

    ngOnInit(): void {
        this.i = this.agefiphService.firstLoad ? 0 : 5;
        if (this.i === 0) {
            this.animLogo();
        }
    }

    ngOnDestroy() {
        clearInterval(this.agefiphService.animationLogo);
        clearInterval(this.agefiphService.animationBadgeAccueil);
    }


    animBadge() {
        let step = 0;
        this.agefiphService.animationBadgeAccueil = setInterval(() => {
            this.badge.nativeElement.src = './assets/animations/badge_1/ADP_gold_000' + step + '.png';
            if (step === 23) {
                clearInterval(this.agefiphService.animationBadgeAccueil)
            } else {
                step++;
            }
        }, 50);
    }

    animLogo() {
        let step = 1;
        this.agefiphService.animationLogo = setInterval(() => {
            this.logo.nativeElement.src = './assets/animations/logo/Animation_Logo_000' + step + '.png';
            if (step === 18) {
                clearInterval(this.agefiphService.animationLogo)
            } else {
                step++;
            }
        }, 50);
    }

    setBg() {
        if (this.i === 0) {
            clearInterval(this.agefiphService.animationLogo);
        } else if (this.i === 1) {
            this.animBadge();
        } else if (this.i === 2) {
            this.agefiphService.firstLoad = false;
            clearInterval(this.agefiphService.animationBadgeAccueil)
            setTimeout(() => {
                this.i = 4;
            }, 3000)
        }
        this.i++;
    }
}

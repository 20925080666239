import { Component } from '@angular/core';
import { AgefiphService } from 'src/app/services/agefiph.service';

@Component({
    selector: 'app-reponse-bool',
    templateUrl: './reponse-bool.component.html',
    styleUrls: ['./reponse.component.scss']
})
export class ReponseBoolComponent {

    constructor(public agefiphService: AgefiphService) {

    }

    select(value: string) {
        this.agefiphService.repSelected = value;
        this.agefiphService.repSelectedSubject.next(value);
    }

}

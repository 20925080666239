import { AfterViewInit, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Carousel } from 'bootstrap';
import { AgefiphService } from 'src/app/services/agefiph.service';

@Component({
    selector: 'app-reponse',
    templateUrl: './reponse.component.html',
    styleUrls: ['./reponse.component.scss']
})
export class ReponseComponent implements AfterViewInit {

    @Input() i = 1;

    @Input() showPopupReponse: string;

    carousel!: Carousel;

    bg = 1;
    canSwipe = true;

    textSize = '10rem';
    lineSize = '11rem';

    block = false;

    tabIndex = '0';

    constructor(public agefiphService: AgefiphService,
        private router: Router) {
        this.bg = this.agefiphService.q.theme_FK;
        this.calculMaxSize();
        this.agefiphService.showPopupReponse$.subscribe(b => {
            this.tabIndex = b ? "-1" : "0";
        });
    }

    ngAfterViewInit(): void {
        this.carousel = new Carousel('#carouselReponses', { interval: false, slide: true });
    }

    calculMaxSize() {
        if (this.agefiphService.q.type === 'qcm_text' || this.agefiphService.q.type === 'qcu_text') {
            let max = Math.max(this.agefiphService.q.answer1_text.length, this.agefiphService.q.answer2_text.length, this.agefiphService.q.answer3_text.length,
                this.agefiphService.q.answer4_text.length, this.agefiphService.q.answer5_text.length, this.agefiphService.q.answer6_text.length);
            if (max >= 100 || this.agefiphService.q.answer5_text || this.agefiphService.q.answer6_text) {
                this.textSize = '7rem';
                this.lineSize = '10rem';
            } else {
                this.textSize = '10rem';
                this.lineSize = '13rem';
            }
        }

    }

    previous() {
        this.block = true;
        this.carousel.prev();
        this.i = this.i > 1 ? this.i - 1 : this.agefiphService.nbRep;
        setTimeout(() => {
            this.block = false;
        }, 1000)
    }

    next() {
        this.block = true;
        this.carousel.next();
        this.i = (this.i % this.agefiphService.nbRep) + 1;
        setTimeout(() => {
            this.block = false;
        }, 1000)
    }

    checkReponse() {
        this.agefiphService.activeValidation = false;
        this.agefiphService.repQCUMSelectedSubject.next(true);
    }

    select() {
        this.agefiphService.repSelected = this.i.toString();
        this.agefiphService.repSelectedSubject.next(this.i.toString())
    }

    revoir() {
        this.agefiphService.revoir = true;
        this.router.navigateByUrl('/' + this.agefiphService.slug, { skipLocationChange: true }).then(() => {
            this.router.navigate(['/' + this.agefiphService.slug + '/questions']);
        });
    }

}

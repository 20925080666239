import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AgefiphService } from 'src/app/services/agefiph.service';

@Component({
    selector: 'app-pop-reponse',
    templateUrl: './pop-reponse.component.html',
    styleUrls: ['./pop-reponse.component.scss']
})
export class PopReponseComponent implements OnInit {

    @Input() isGood = false;

    showPopupReponse = false;
    showIntermede = false;
    subscription = new Subscription();
    handle: any;
    fond = '';
    theme = '';
    detail = "";

    constructor(public agefiphService: AgefiphService,
        private router: Router) {
        this.subscription = this.agefiphService.showPopupReponse$.subscribe(b => {
            this.showPopupReponse = b;
            if (this.agefiphService.index === 0 && b) {
                this.next();
            }
            if (this.agefiphService.index === 21) {
                this.showIntermede = true;
                this.showPopupReponse = false;
                this.fond = this.agefiphService.orientation === 'portrait' ? 'bg-' + 4 + '-transition bg-mains bg-img' : 'bg-' + 4 + '-transition bg-mains-web bg-img';
                this.theme = this.agefiphService.datas.data.wordings['ui.quiz.question_title_' + 4];
                this.detail = this.agefiphService.datas.data.wordings['ui.quiz.question_label_' + 4];
            }
        });
    }

    ngOnInit(): void {
        this.showPopupReponse = false;
        if (this.agefiphService.index === 21) {
            this.showIntermede = true;
            this.showPopupReponse = false;
            this.fond = this.agefiphService.orientation === 'portrait' ? 'bg-' + 4 + '-transition bg-mains bg-img' : 'bg-' + 4 + '-transition bg-mains-web bg-img';
            this.theme = this.agefiphService.datas.data.wordings['ui.quiz.question_title_' + 4];
            this.detail = this.agefiphService.datas.data.wordings['ui.quiz.question_label_' + 4];
        }
    }

    next() {
        let themeActuel = this.agefiphService.q.theme_FK;
        if (!this.agefiphService.datas.data.questions[this.agefiphService.index + 1]) {
            this.nextSure();
        } else {
            let nouveauTheme = this.agefiphService.datas.data.questions[this.agefiphService.index + 1].theme_FK;
            if ((themeActuel !== nouveauTheme || this.agefiphService.index === 0) && nouveauTheme !== 0) {
                if (nouveauTheme === 0) {
                    nouveauTheme = 4;
                }
                this.showIntermede = true;
                this.showPopupReponse = false;
                this.fond = this.agefiphService.orientation === 'portrait' ? 'bg-' + nouveauTheme + '-transition bg-mains bg-img' : 'bg-' + nouveauTheme + '-transition bg-mains-web bg-img';
                this.theme = this.agefiphService.datas.data.wordings['ui.quiz.question_title_' + nouveauTheme];
                this.detail = this.agefiphService.datas.data.wordings['ui.quiz.question_label_' + nouveauTheme];
            } else {
                this.nextSure();
            }
        }
    }

    nextSure() {
        this.agefiphService.index++;
        this.agefiphService.showPopupReponse.next(false);
        this.agefiphService.repQCUMSelected = ['0', '0', '0', '0', '0', '0'];
        this.router.navigateByUrl('/' + this.agefiphService.slug, { skipLocationChange: true }).then(() => {
            this.router.navigate(['/' + this.agefiphService.slug + '/questions']);
        });
    }

}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AgefiphService } from '../services/agefiph.service';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

    showPopup = false;

    constructor(public agefiphService: AgefiphService,
        private router: Router) {
        this.agefiphService.setTitle(agefiphService.datas.data.wordings['ui.page.menu'] + ' | ' + agefiphService.datas.data.wordings['ui.page.agefiph'])
    }

    home() {
        this.agefiphService.firstLoad = false;
    }
}

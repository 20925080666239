import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'findPipe'
})
export class FindPipe implements PipeTransform {

    constructor() { }

    transform(array: number[], chiffre: number) {
        return array.some(a => a === chiffre);
    }

}
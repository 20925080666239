export class Progression {
    badge: boolean;
    scores: {
        1: number;
        2: number;
        3: number;
    };

    constructor() {
        this.badge = false;
        this.scores = {
            1: 0,
            2: 0,
            3: 0
        }
    }
}

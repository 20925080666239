import { Top } from "./top";

export class LeaderBoard {
    intra: {
        list: string[];
        playerRank: number
    };
    inter: {
        tops: Top[];
        arounds: Top[];
        companyRank: number
    };

    constructor() {
        this.intra = {
            list: [],
            playerRank: -1
        };
        this.inter = {
            tops: [],
            arounds : [],
            companyRank: -1
        };
    }

}

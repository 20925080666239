import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { AgefiphService } from './services/agefiph.service';

declare var tarteaucitron;
declare var _paq:any;
//declare function gtag(arg1, arg2);
@Component({
    selector: 'app-agefiph',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy {
    _paq:any;

    @ViewChild('loading') loading: ElementRef;

    constructor(public agefiphService: AgefiphService) {
        this.animLoading();
    }

    ngAfterViewInit() {
        this.resetFontSize();
    }

    ngOnDestroy() {
        clearInterval(this.agefiphService.animationLoading);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any): void {
        this.resetFontSize();
        this.agefiphService.resize();
    }

    @HostListener('click', ['$event'])
    onClick(event: any): void {
        let cookie = tarteaucitron.cookie.read();
        let isAllowed = cookie.indexOf('matomo=true') >= 0;
        if (isAllowed) {
            //gtag('event', 'alive');
            _paq.push([
				'trackEvent',
				'alive',
				"alive",
                null,
                null,
				{dimension1: this.agefiphService.slug}
			]);
        }
    }

    animLoading() {
        let step = 0;
        this.agefiphService.animationLoading = setInterval(() => {
            if (this.loading) {
                this.loading.nativeElement.src = './assets/animations/loading/Loading_000' + step + '.png';
                if (step === 16) {
                    clearInterval(this.agefiphService.animationLoading);
                } else {
                    step++;
                }
            }
        }, 100);
    }

    private resetFontSize() {
        (document.querySelector('html') as HTMLElement).style.setProperty('--custom-font-size', this.calcFontSize());
        (document.querySelector('html') as HTMLElement).style.setProperty('--height-up', this.calcHeight());
        this.agefiphService.heightZoom = this.calcHeightZoom();
    }

    calcHeight() {
        let delta = Math.round(((window.outerWidth - 10) / window.innerWidth) * 100);
        if (delta > 200) {
            delta = 200;
        }
        return Math.max(delta, 100) + '%'
    }

    calcHeightZoom() {
        let delta = Math.round(((window.outerWidth - 10) / window.innerWidth) * 100);
        if (delta > 200) {
            delta = 200;
        }
        return Math.max(delta, 100);
    }


    private calcFontSize() {
        let delta = Math.round(((window.outerWidth - 10) / window.innerWidth) * 20) / 20;
        if (delta > 2) {
            delta = 2;
        }
        if (window.innerWidth > window.innerHeight) {
            let ratio = window.innerWidth / window.innerHeight;
            let fontSize = ratio > (32 / 19) ? 0.35 : (ratio > (16 / 13) ? 0.25 : 0.21);
            return fontSize * delta + 'vh';
        } else {
            let ratio = window.innerWidth / window.innerHeight;
            let fontSize = ratio > (9 / 10) ? 0.35 : 0.5;
            return fontSize + 'vw';
        }
    }

}

import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BadgeData } from '../entites/badgeData';
import { AgefiphService } from '../services/agefiph.service';

@Component({
    selector: 'app-partage-badge',
    templateUrl: './partage-badge.component.html',
    styleUrls: ['./partage-badge.component.scss']
})
export class PartageBadgeComponent {

    fond = '';
    badgeData = new BadgeData();
    bgBadge = '';
    badges = '';

    i = 1;
    KO = false;

    url1 = environment.apiUrl + "/badge/getBackground/";
    url2 = environment.apiUrl + "/badge/getCombinaison/";

    constructor(public agefiphService: AgefiphService) {

        this.agefiphService.setTitle(agefiphService.datas.data.wordings['ui.page.partage'] + ' | ' + agefiphService.datas.data.wordings['ui.page.agefiph'])

        this.badgeData.name = this.agefiphService.playerData.data.pseudo ? this.agefiphService.playerData.data.pseudo : '';
        this.badgeData.background = this.i;
        this.fond = this.agefiphService.datas.data.wordings['ui.share.text.background'].replace("#numFond", "1");
        this.bgBadge = this.url1 + 'background_' + this.i + '.png';
        this.badges = this.url2 + 'badge.png';

    }

    genereBadge() {
        this.badgeData.background = this.i;
        this.agefiphService.generateBadge(this.badgeData).subscribe(badgeImg => {
            this.agefiphService.urlPartage = badgeImg.data;
        })
    }

    previous() {
        if (this.i === 1) {
            this.i = 4;
        } else {
            this.i--;
        }
        this.bgBadge = this.url1 + 'background_' + this.i + '.png';
        this.fond = this.agefiphService.datas.data.wordings['ui.share.text.background'].replace("#numFond", this.i);
    }

    next() {
        if (this.i === 4) {
            this.i = 1;
        } else {
            this.i++;
        }
        this.bgBadge = this.url1 + 'background_' + this.i + '.png';
        this.fond = this.agefiphService.datas.data.wordings['ui.share.text.background'].replace("#numFond", this.i);
    }

}
